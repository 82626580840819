import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() courses;
  @Output() openPopup = new EventEmitter();
  socials = environment.socials;
  contacts = environment.contact;
  about={
    heading:"",
    text:""
  }
  constructor(private api: ApiService) { }

  ngOnInit() {

    // this.api.Request("course", {}).subscribe(resp => {
    //   this.courses = resp;
    // });
    // this.api.Request("comps", {}).subscribe(resp => {
    //   this.about = resp.aboutFooter;
    // });
    // this.api.Request("contact",{}).subscribe(resp=>{
    //   this.contacts=resp;
    // });
    // this.api.Request("social",{}).subscribe(resp=>{
    //   this.socials=resp;
    // });

  }
  OpenFeedback() {
    this.openPopup.emit("feedback");
  }

}
