import { Component, OnInit, Input, Output, HostListener } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DatastoreService } from 'src/app/services/datastore.service';

@Component({
  selector: 'app-popups',
  templateUrl: './popups.component.html',
  styleUrls: ['./popups.component.scss'],
})
export class PopupsComponent implements OnInit {
  @Input("name") name;
  @Input("notice") notice;
  @Output() closePopup=new EventEmitter();
  @HostListener("window:keydown.escape") keypressed(){
    if(this.name!=""){
      this.ClosePopup();
    }
  }
  apply = {
    name: "",
    email: "",
    phone: "",
    course: 0
  }
  enquiry = {
    name: "",
    email: "",
    phone: "",
    query: ""
  }
  feedback = {
    student: {
      email: "",
      phone: "",
      name: ""
    },
    about: "",
    message: ""
  }
  passwd="password";
  student={
    user:"",
    pass:""
  };

  constructor(private api:ApiService,private store:DatastoreService) { 


  }

  ngOnInit() {

   }
  ClosePopup() {
    this.name = "";
    this.closePopup.emit("");
  }
  ApplyForCourse(){
    this.AdmissionRequest();
  }
  MakeEnquiry(){
    this.api.Request("enquiry/add",this.enquiry).subscribe(resp=>{
      console.log(resp);
      this.notice={
        info:"",
        heading:"Your request submitted",
        details:"Thankyou for showing your interest in us. One of our correspondent will get back to you shortly."
      }
      this.name="notice";
      setTimeout(() => {
        this.ClosePopup();
      }, 10000);
    });
  }
  AdmissionRequest(){
    this.api.Request("course/application",this.apply).subscribe(r=>{
      console.log(r);
      this.notice={
        info:"",
        heading:"Your request submitted",
        details:"Dear student, Thankyou for showing your interest in admission. One of our correspondent will get back to you shortly."
      }
      this.name="notice";
      setTimeout(() => {
        this.ClosePopup();
      }, 10000);
    });
  }
  SendFeedback(){
    this.api.Request("feedback",this.feedback).subscribe(resp=>{
      console.log(resp);
      this.notice={
        info:"",
        heading:"Your feedback is submitted",
        details:"Dear student, Your feedback has been submitted successfully."
      }
      this.name="notice";
      setTimeout(() => {
        this.ClosePopup();
      }, 10000);
    });
  }
  ShowPassword(){
    this.passwd="text";
    setTimeout(() => {
      this.passwd="password"
    }, 10000);
  }
  Notify(e:any){
    this.notice=e;
    this.name="notice";
    setTimeout(() => {
      this.name="";
    }, 10000);
  }
  LoginAsStudent(){
    this.api.Request("student/login", this.student).subscribe(resp => {
      if ("error" in resp) {
        this.Notify({
          heading: "Error",
          details: resp.error
        });
      } else {
        this.store.store("student", resp).then(resp=>{
          document.location.href="/student-zone";
        }); 
      }
    })    
  }
  KeyPressEvent(e){
    console.log(e);
  }
}
