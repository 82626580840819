import { Component } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {
  test="Ye test info ki value hai";
  why=environment.why;
  video=environment.video;
  public navopenclass="";
  slideOpts = {
    // direction: 'vertical',
    spaceBetween: 30,
    speed: 2000,
    autoplay: {
      delay: 3000,
    },
    loop: true,
    slidesPerView: 3,
    breakpoints: {
      991: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 80,
      },
    }
  };
  sliderenet = {
    // direction: 'vertical',
    spaceBetween: 30,
    speed: 2000,
    autoplay: {
      delay: 3000,
    },
    loop: true,
    slidesPerView: 3,
    breakpoints: {
      991: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 80,
      },
    }
  };
  slideUpcoming = {
    spaceBetween: 20,
    speed: 1000,
    autoplay: {
      delay: 5000,
    },
    loop: true,
    slidesPerView: 1,
    breakpoints: {
      991: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      1920: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    }
  };
  
  slideAdmission = {
    spaceBetween: 20,
    speed: 1000,
    autoplay: {
      delay: 3000,
    },
    loop: true,
    slidesPerView: 1,
    breakpoints: {
      991: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      1920: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
    }
  };
  noticeSlider = {
    spaceBetween: 20,
    speed: 1000,
    autoplay: {
      delay: 3000,
    },
    loop: true,
    slidesPerView: 1,
    breakpoints: {
      991: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      1920: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
    }
  };
  notices=[ 
  ];
  popup="";
  courses:any;
  course:any;
  performers=[
    {
      name:"Kavita Kumari",
      img:"/assets/admission.jpg",
      rank:"#1"
    },
    {
      name:"Prakash Mehta",
      img:"/assets/admission-2.jpg",
      rank:"#2"
    },
    {
      name:"Prachi Kumari",
      img:"/assets/admission.jpg",
      rank:"#3"
    }
  ];
notice="";
enquiry={
  name:"",
  email:"",
  phone:"",
  query:""
}
imgpath=environment.imgpath;
  constructor(private api:ApiService) {}
  ngOnInit() {
    // this.api.Request("notice",{}).subscribe(r=>{
    //   this.notices=r;
    //   console.log(this.notices);
    // });
    // this.api.Request("course",{}).subscribe(r=>{
    //   this.courses=r;
    //   console.log(r);
    // });
    // this.api.Request("comps",{}).subscribe(resp=>{
    //   this.video=resp.video;
    //   this.why=resp.why;
    // })
  }
  
  OpenNotice(n:any){
    this.popup="notice";
    this.notice=n;
  }
  ResetPopups(){
    this.popup="";
  }
  public OpenFeedbackForm(){
    this.popup="feedback";
  }
  public ClosePopup() {
    this.ResetPopups();
  }
  public ApplyCourse(crs:any){
    this.popup="courseApplication";
  }
  MakeEnquiry(){
    var n:any;
    this.api.Request("enquiry/add",this.enquiry).subscribe(resp=>{
      console.log(resp);
      n={
        info:"",
        heading:"Your request submitted",
        details:"Thankyou for showing your interest in us. One of our correspondent will get back to you shortly."
      }
      this.OpenNotice(n);
      setTimeout(() => {
        this.ClosePopup();
      }, 10000);
    });
  }
  // SendFeedback(){
  //   this.api.Request("feedback",this.feedback).subscribe(resp=>{
  //     this.ResetPopups();
  //     this.popupForms.notice=true;
  //     this.notice={
  //       info:"",
  //       heading:"Your feedback submitted",
  //       details:"Dear Student, your feedback has been submitted. One of our correspondet will contact you shortly."
  //     };
  //   })
  // }
  // public ApplyCourse(crs:any) {
  //   this.ResetPopups();
  //   this.popupForms.applyCourse=true;
  //   this.course=crs;
  //   this.apply.course=crs.id;
  // }
  // public ApplyForCourse(){
  //   this.api.Request("course/application",this.apply).subscribe(r=>{
  //     this.ResetPopups();
  //     this.popupForms.notice=true;
  //     this.notice={
  //       info:"",
  //       heading:"Your request submitted",
  //       details:"Dear Student, your request has been submitted. One of our correspondet will contact you shortly."
  //     };
  //   });
  // }
  // public MakeEnquiry(){
  //   this.api.Request("enquiry/add",this.enquiry).subscribe(resp=>{
  //     this.popupForms.notice=true;
  //     this.notice={
  //       info:"",
  //       heading:"Your request submitted",
  //       details:"Dear Student, your request has been submitted. One of our correspondet will contact you shortly."
  //     };
  //   })

  // }
  public PopupClosed(){
    this.popup="";
  }
  public OpenPopup(e:any){
    this.popup=e;
    console.log(e);
  }
  public Test(){
    console.log(this.test);
  }
  public Enquery() {
    this.popup="enquiry";
  }
  public Admission() {
    this.popup="admission"
    }
  public Download(e){
    console.log(e);
  }  
  public KeyPressed(e){
    console.log(e);
  }
}
