import { Component } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.page.html',
  styleUrls: ['./about-us.page.scss'],
})
export class AboutUsPage {
  about={
    heading:"What we are",
    text:"Separated they live in. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth."
  }
  video={
    heading:"Rai Institute of English Language",
    text:"Separated they live in. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth."
  }
  popup="";
  constructor(private api:ApiService) { }
ngOnInit(){
  this.api.Request("comps",{}).subscribe(resp=>{
    this.video=resp.aboutVideo;
    this.about=resp.about;
  })
}
  public PopupClosed() {
    this.popup = "";
  }
  public OpenPopup(e: any) {
    this.popup = e;
    console.log(e);
  }

}
