import { Component } from '@angular/core';

@Component({
  selector: 'app-upcoming-courses',
  templateUrl: './upcoming-courses.page.html',
  styleUrls: ['./upcoming-courses.page.scss'],
})
export class UpcomingCoursesPage {
  popup = "";
  notice: any;
  constructor() { }

  ngOnInit() {
  }
  public PopupClosed() {
    this.popup = "";
  }
  public OpenPopup(e: any) {
    this.popup = e;
    console.log(e);
  }
  public Notify(n) {
    this.notice = n;
    this.OpenPopup("notice");
    setTimeout(() => {
      this.PopupClosed();
    }, 10000);
  }
  ApplyCourse(){
    this.popup="apply";
  }
}
