import { Component } from '@angular/core';

@Component({
  selector: 'app-news',
  templateUrl: './news.page.html',
  styleUrls: ['./news.page.scss'],
})
export class NewsPage  {
  popup="";
  constructor() { }
  public PopupClosed() {
    this.popup = "";
  }
  public OpenPopup(e: any) {
    this.popup = e;
    console.log(e);
  }
}
