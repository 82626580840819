import { Injectable } from '@angular/core';
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;
@Injectable({
  providedIn: 'root'
})
export class DatastoreService {

  constructor() { }
  public async store(key: string, value: any) {
    const encval = btoa(escape(JSON.stringify(value)));
    await Storage.set({
      key: key,
      value: encval
    });
  }
  public async get(key: string) {
    const ret = await Storage.get({ key: key });
    return JSON.parse(unescape(atob(ret.value)));

  }
  public async delete(key: string) {
    await Storage.remove({ key: key });
  }
  public async clear() {
    await Storage.clear();
  }
  public async has(key: string) {
    const { keys } = await Storage.keys();
    return (keys.indexOf(key) != -1);
  }
}
