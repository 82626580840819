import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.page.html',
  styleUrls: ['./gallery.page.scss'],
})
export class GalleryPage implements OnInit {
  popup = "";
  constructor() { }

  ngOnInit() {
  }
  public PopupClosed() {
    this.popup = "";
  }
  public OpenPopup(e: any) {
    this.popup = e;
    console.log(e);
  }
}
