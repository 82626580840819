import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
})
export class TestimonialsComponent implements OnInit {
  slideTestimonial = {
    spaceBetween: 20,
    speed: 1000,
    autoplay: {
      delay: 5000,
    },
    loop: true,
    slidesPerView: 1,
    breakpoints: {
      991: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      1920: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
    }
  };
  constructor() { }

  ngOnInit() {}

}
