import { Component, OnInit, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DatastoreService } from 'src/app/services/datastore.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  downloads = [
    "I-Card",
    "Question Paper",
    "Assignments",
    "Daily Schedule",
    "Attendance",
    "Certificate"
  ];
  @Input() test;
  @Output() openPopup = new EventEmitter();
  navopenclass = "";
  socials = environment.socials;
  contacts = environment.contact;
  login = 0;
  student: any;
  constructor(private api: ApiService, private store: DatastoreService) { }

  ngOnInit() {
    this.store.has("student").then(r => {
      if(r){
        this.login = 1;
        this.store.get("student").then(res => {
          console.log(res);
          this.student = res;
        });
      }
      else{
        this.login=0;
      }
    }).catch(e => {
      this.login = 0;
    })
    // this.api.Request("contact", {}).subscribe(resp => {
    //   this.contacts = resp;
    // });
    // this.api.Request("social", {}).subscribe(resp => {
    //   this.socials = resp;
    // });
  }
  public ToggleNavbar() {
    this.navopenclass = (this.navopenclass == "mobile-navbar-open") ? "" : "mobile-navbar-open";
    this.test = "Navbar open hai";
    console.log("Test ki value badal gai");
    console.log(this.test);
  }
  OpenEnquiry() {
    this.openPopup.emit("enquiry");
  }
  OpenAdmission() {
    this.openPopup.emit("admission");
  }
  LoginAsStudent(){
    this.openPopup.emit("student-login");
  }
}
