import { Component, Query } from '@angular/core';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.page.html',
  styleUrls: ['./contact-us.page.scss'],
})
export class ContactUsPage {
  popup="";
  notice:any;
  enquiry = {
    name: "",
    fname: "",
    lname: "",
    email: "",
    phone: "",
    query: ""
  };
  public constructor(private api:ApiService) { }

  public MakeEnquiry(){
    this.api.Request("enquiry/add",this.enquiry).subscribe(resp=>{
      this.OpenPopup("notice");
      this.notice={
        info:"",
        heading:"Your request submitted",
        details:"Dear Student, your request has been submitted. One of our correspondet will contact you shortly."
      };
      setTimeout(() => {
        this.PopupClosed();
      }, 10000);
    })

  }
  public PopupClosed() {
    this.popup = "";
  }
  public OpenPopup(e: any) {
    this.popup = e;
    console.log(e);
  }
}
