import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AboutUsPage } from './page/about-us/about-us.page';
import { ContactUsPage } from './page/contact-us/contact-us.page';
import { GalleryPage } from './page/gallery/gallery.page';
import { HomePage } from './page/home/home.page';
import { NewsPage } from './page/news/news.page';
import { PolicyPage } from './page/policy/policy.page';
import { StudentsZonePage } from './page/students-zone/students-zone.page';
import { UpcomingCoursesPage } from './page/upcoming-courses/upcoming-courses.page';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { 
    path: 'home',
    component: HomePage
    //loadChildren: () => import('./page/home/home.module').then( m => m.HomePageModule),
  },
  {
    path: 'about-us',
    component: AboutUsPage
    //loadChildren: () => import('./page/about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'news',
    component: NewsPage
    //loadChildren: () => import('./page/news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'contact-us',
    component: ContactUsPage
    //loadChildren: () => import('./page/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'upcoming-courses',
    component: UpcomingCoursesPage
    //loadChildren: () => import('./page/upcoming-courses/upcoming-courses.module').then( m => m.UpcomingCoursesPageModule)
  },
  {
    path: 'policy/:name',
    component: PolicyPage
    //loadChildren: () => import('./page/policy/policy.module').then( m => m.PolicyPageModule)
  },
  {
    path: 'gallery',
    component: GalleryPage
    //loadChildren: () => import('./page/gallery/gallery.module').then( m => m.GalleryPageModule)
  },
  {
    path: 'students-zone',
    component: StudentsZonePage
    //loadChildren: () => import('./page/students-zone/students-zone.module').then( m => m.StudentsZonePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
