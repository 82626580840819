import { Component, OnInit } from '@angular/core';
import { DatastoreService } from 'src/app/services/datastore.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-students-zone',
  templateUrl: './students-zone.page.html',
  styleUrls: ['./students-zone.page.scss'],
})
export class StudentsZonePage implements OnInit {
  popup = "";
  notice: any;
  student = {
    user: "",
    pass: ""
  }
  show = 1;
  info: any;
  crs: 0;
  courses: any;
  list = [
    "I-Card",
    "Time Table",
    "Attendance",
    "Assignments",
    "Examination",
    "Certificate",
    "Logout"
  ];
  questions = [];
  attempt = [];
  constructor(private store: DatastoreService, private api: ApiService) { }

  ngOnInit() {
    this.api.Request("course", {}).subscribe(resp => {
      this.courses = resp;
    })
    this.store.has("student").then(r => {
      if (r) {
        this.store.get("student").then(resp => {
          this.info = resp;
          this.show = 2;
        })
      } else {
        this.show = 1;
      }
    })
  }
  public PopupClosed() {
    this.popup = "";
  }
  public OpenPopup(e: any) {
    this.popup = e;
    console.log(e);
  }
  public Notify(n) {
    console.log(n);
    this.notice = n;
    this.OpenPopup("notice");
    setTimeout(() => {
      this.PopupClosed();
    }, 10000);
  }
  public Login() {
    this.api.Request("student/login", this.student).subscribe(resp => {
      if ("error" in resp) {
        this.Notify({
          heading: "Error",
          details: resp.error
        });
      } else {
        this.store.store("student", resp);
        this.info = resp;
        this.show = 2;
      }
    })
  }
  public OpenPage(page: string) {
    switch (page) {
      case this.list[0]:
        this.show = 3;
        break;
      case this.list[1]:
        this.show = 4;
        break;
      case this.list[2]:
        this.show = 5;
        break;
      case this.list[3]:
        this.show = 6;
        break;
      case this.list[4]:
        this.show = 7;
        break;
      case this.list[5]:
        this.show = 8;
        break;
      case this.list[6]:
        this.Logout()
        break;

      default:
        break;
    }
  }
  Logout(){
    this.store.delete("student").then(resp=>{
      document.location.href="/";
    })
  }
  StartExam(e: any) {
    var data = {
      student: this.info.id,
      course: e.id
    }
    this.crs = e.id;
    this.api.Request("student/exams", data).subscribe(resp => {
      if ("error" in resp) {
        this.Notify({
          heading: "Error",
          details: resp.error
        });
      } else {
        this.questions = resp;
        this.show = 10;
      }
    })
  }
  SubmitExams() {
    var myAttempt = [];
    var i = 0;
    this.questions.forEach((que) => {
      myAttempt.push({ que: que.id, ans: this.attempt[i] });
      i++;
    });

    var data = {
      student: this.info.id,
      course: this.crs,
      attempt: myAttempt
    }
    this.api.Request("student/attempt", data).subscribe(resp => {
      this.show = 2;
      this.Notify({
        heading: "Examination Over",
        details: "Dear student, You scored " + resp.marks + " out of " + resp.mm
      });
    });
  }
}
