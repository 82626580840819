import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.page.html',
  styleUrls: ['./policy.page.scss'],
})
export class PolicyPage {
  popup="";
  content="";
  constructor(private router:Router,private api:ApiService) { 
  }
  public PopupClosed() {
    this.popup = "";
  }
  public OpenPopup(e: any) {
    this.popup = e;
    console.log(e);
  }
  ngOnInit() {
    this.api.Request("page-content",{url:this.router.url}).subscribe(resp=>{
      console.log(resp);
    });
    console.log(this.router.url);
    console.log(this.router)
  }

}
