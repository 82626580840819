import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http:HttpClient) { }
  Request(serv:string,data:any):Observable<any>{
    const headers = new HttpHeaders();
    const options = { headers: headers, withCredentials: false }
    const url = environment.api + serv;
    return this.http.post(url, JSON.stringify(data), options);
  }

}
